import { addDays, getUnixTime } from 'date-fns'
import { produce } from 'immer'
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export interface Session {
  userId?: string
  isIncumbent?: boolean
  businessId?: string
  employeeId?: string
  workplaceId?: string
  expireAt?: number
  mode?: 'timeclock'
}

export const sessionAtom = atomWithStorage<Session>('session', {})

type Action =
  | {
      type: 'login'
      userId?: string
      isIncumbent?: boolean
      businessId?: string
      employeeId?: string
      workplaceId?: string
    }
  | { type: 'update'; session: Session }
  | { type: 'logout' }

export const sessionReducer = (prev: Session, action: Action) => {
  switch (action.type) {
    case 'login':
      return produce(prev, draft => {
        draft.userId = action.userId
        draft.isIncumbent = action.isIncumbent
        draft.businessId = action.businessId
        draft.employeeId = action.employeeId
        draft.workplaceId = action.workplaceId
        draft.expireAt = getUnixTime(addDays(new Date(), 59))
      })

    case 'update':
      return produce(prev, draft => {
        Object.entries(action.session).forEach(([k, v]) => {
          // @ts-ignore
          draft[k] = v
        })
      })

    default:
      throw new Error(`unknown action type: ${action.type}`)
  }
}

export const businessModeAtom = atom(get => !get(sessionAtom).workplaceId)
export const sessionModeAtom = atom(get => get(sessionAtom).mode)
