import { workplaceDailyReportsAtom } from 'components/pages/ReportsPage/_components/DailyReportsPage/_atoms/WorkplaceDailyReports'
import useWorkplaceId from 'components/pages/ReportsPage/_hooks/useWorkplaceId'
import { useSetAtom } from 'jotai'

function useQueryDailyReport(date: string) {
  const workplaceId = useWorkplaceId()
  return useSetAtom(workplaceDailyReportsAtom(`${workplaceId}:${date}:${date}`))
}

export default useQueryDailyReport
