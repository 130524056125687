import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { targetRevenueSettingsAtom } from 'atoms/Workplace/TargetRevenueSettings'
import { BusinessWorkplaceTargetRevenueSetting } from 'gql/types'
import getRoundedNumber from 'helpers/getRoundedNumber'
import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useCreateWorkplaceTargetRevenueSettingDialog } from './_components/CreateWorkplaceTargetRevenueSettingDialog'
import { useEditWorkplaceTargetRevenueSettingDialog } from './_components/EditWorkplaceTargetRevenueSettingDialog'

interface Props {
  workplaceId: string
}

function WorkplaceTargetRevenueSettings({ workplaceId }: Props) {
  const [targetRevenueSettings, queryTargetRevenueSettings] = useAtom(
    targetRevenueSettingsAtom(workplaceId)
  )

  const createWorkplaceTargetRevenueSettingDialogContext =
    useCreateWorkplaceTargetRevenueSettingDialog()
  const editWorkplaceTargetRevenueSettingDialogContext =
    useEditWorkplaceTargetRevenueSettingDialog()

  useEffect(() => queryTargetRevenueSettings(), [queryTargetRevenueSettings])

  const handleShowCreateTargetRevenueSettingDialog = () => {
    createWorkplaceTargetRevenueSettingDialogContext?.showDialog(workplaceId)
  }

  const handleShowEditTargetRevenueSettingDialog =
    (targetRevenueSetting: BusinessWorkplaceTargetRevenueSetting) => () => {
      editWorkplaceTargetRevenueSettingDialogContext?.showDialog(
        targetRevenueSetting,
        workplaceId
      )
    }

  const renderSetting = (setting: number | null) =>
    setting ? (
      `${getRoundedNumber(setting / 10000)}萬`
    ) : (
      <span style={{ color: 'orange' }}>店休</span>
    )

  const rows =
    targetRevenueSettings?.map(
      ({ id, startDate, targetRevenueSettingJson }) => ({
        id,
        workplaceId,
        startDate,
        ...JSON.parse(targetRevenueSettingJson),
      })
    ) || []

  return (
    <>
      <Button
        style={{ position: 'absolute', right: 20, top: 20 }}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        size="small"
        onClick={handleShowCreateTargetRevenueSettingDialog}
      >
        新增設定
      </Button>
      <TableContainer component={Paper} style={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週一
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週二
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週三
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週四
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週五
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週六
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                週日
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                國定假日
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                設定生效日期
              </TableCell>
              <TableCell style={{ fontWeight: 600 }} align="center">
                編輯
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                {[
                  'mon',
                  'tue',
                  'wed',
                  'thu',
                  'fri',
                  'sat',
                  'sun',
                  'holiday',
                ].map(day => (
                  <TableCell align="center">
                    {renderSetting(row[day])}
                  </TableCell>
                ))}
                <TableCell align="center">{row.startDate}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ color: 'grey', cursor: 'pointer' }}
                    onClick={handleShowEditTargetRevenueSettingDialog(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default WorkplaceTargetRevenueSettings
