import useWorkplaceId from '../../_hooks/useWorkplaceId'
import DailyReports from './_components/DailyReports'
import Header from './_components/Header'
import styles from './index.module.css'

function DailyReportsPage() {
  const workplaceId = useWorkplaceId()

  if (!workplaceId) return null

  return (
    <div className={styles.root}>
      <Header />
      <DailyReports />
    </div>
  )
}

export default DailyReportsPage
