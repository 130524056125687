import { gqlClientAtom } from 'gql'
import {
  BusinessWorkplaceTargetRevenueSetting,
  CreateTargetRevenueSettingInput,
  DeleteTargetRevenueSettingInput,
  UpdateTargetRevenueSettingInput,
} from 'gql/types'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import {
  CreateTargetRevenueSetting,
  DeleteTargetRevenueSetting,
  GetBusinessWorkplaceDailyReports,
  UpdateTargetRevenueSetting,
} from './_gql'

const queryResultAtom = atomFamily((workplaceId: string) =>
  atom<BusinessWorkplaceTargetRevenueSetting[] | null>(null)
)

export const targetRevenueSettingsAtom = atomFamily((workplaceId: string) =>
  atom(
    get => get(queryResultAtom(workplaceId)),
    (get, set) => {
      const queryData = async () => {
        const client = get(gqlClientAtom)
        if (!client) return
        const result = await client
          .query(GetBusinessWorkplaceDailyReports, {
            workplaceId,
          })
          .toPromise()
        if (result.data) {
          set(queryResultAtom(workplaceId), parse(result))
        }
      }
      queryData()
    }
  )
)

const parse = (result: any): BusinessWorkplaceTargetRevenueSetting[] | null =>
  result?.data?.businessWorkplaceTargetRevenueSettings

export const createTargetRevenueSettingAtom = atom(
  null,
  async (get, set, input: CreateTargetRevenueSettingInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(CreateTargetRevenueSetting, {
        input,
      })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(targetRevenueSettingsAtom(input.targetRevenueSetting?.workplaceId!))
    }
  }
)

export const updateTargetRevenueSettingAtom = atom(
  null,
  async (get, set, input: UpdateTargetRevenueSettingInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(UpdateTargetRevenueSetting, {
        input,
      })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(targetRevenueSettingsAtom(input.targetRevenueSetting?.workplaceId!))
    }
  }
)

export const deleteTargetRevenueSettingAtom = atom(
  null,
  async (get, set, input: DeleteTargetRevenueSettingInput) => {
    const client = get(gqlClientAtom)
    if (!client) throw new Error('gqlClient not setup')
    const { error } = await client
      .mutation(DeleteTargetRevenueSetting, {
        input,
      })
      .toPromise()
    if (error) {
      throw error
    } else {
      set(targetRevenueSettingsAtom(input.workplaceId!))
    }
  }
)
