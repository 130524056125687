import NoteIcon from '@mui/icons-material/Assignment'
import UnQualifiedIcon from '@mui/icons-material/Cancel'
import OffsiteIcon from '@mui/icons-material/Room'
import CellDate from 'components/atoms/CellDate'
import format from 'date-fns/format'
import { BusinessWorkplaceShift } from 'gql/types'
import isLowRes from 'helpers/isLowRes'
import parseDate from 'helpers/parseDate'
import styles from './index.module.css'

interface Props {
  shift: BusinessWorkplaceShift
  unQualified?: boolean
  showDate?: boolean
  showPosition?: boolean
  isDraft?: boolean
}

function ShiftCell({
  shift,
  unQualified = false,
  showDate = true,
  showPosition = true,
  isDraft = false,
}: Props) {
  const startTime = format(parseDate(`1970-01-01T${shift.startTime}`), 'HH:mm')
  const endTime = format(parseDate(`1970-01-01T${shift.endTime}`), 'HH:mm')

  return (
    <div
      className={styles.root}
      style={{
        backgroundColor: shift.position.color,
      }}
    >
      {isDraft && <div className={styles['root-draft']} />}

      <div className={styles['left-container']}>
        {showDate && <CellDate date={shift.date} />}
        {showPosition && (
          <div className={styles['position-container']}>
            <span className={styles.text}>{shift.position.name}</span>
            <div
              style={{
                position: 'absolute',
                top: 0,
                height: '100%',
                right: isLowRes ? 35 : 40,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {(shift.offsiteWorkplace ||
                shift.position.workingArea?.offsite) && (
                <OffsiteIcon
                  style={{
                    color: 'white',
                    backgroundColor: shift.position.color,
                    width: isLowRes ? 12 : 14,
                    height: isLowRes ? 12 : 14,
                    marginLeft: 2,
                  }}
                />
              )}
              {shift.note && (
                <NoteIcon
                  style={{
                    color: 'white',
                    backgroundColor: shift.position.color,
                    width: isLowRes ? 11 : 13,
                    height: isLowRes ? 11 : 13,
                    marginLeft: 2,
                  }}
                />
              )}
            </div>

            {!shift.offsiteWorkplace && unQualified && (
              <UnQualifiedIcon
                style={{
                  ...(shift.position.name.length > 4
                    ? { position: 'absolute', right: isLowRes ? 35 : 40 }
                    : {}),
                  color: 'white',
                  backgroundColor: shift.position.color,
                  width: isLowRes ? 12 : 14,
                  height: isLowRes ? 12 : 14,
                  marginLeft: 2,
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className={styles['right-container']}>
        <span className={styles.text}>{startTime}</span>
        <span className={styles.text}>{endTime}</span>
      </div>
    </div>
  )
}

export default ShiftCell
