import React, { createContext, useContext, useState } from 'react'
import CreateWorkplaceTargetRevenueSettingDialog from './CreateWorkplaceTargetRevenueSettingDialog'

interface ContextProps {
  open: boolean
  closeDialog: () => void
  showDialog: (workplaceId: string) => void
}

const context = createContext<ContextProps | null>(null)

interface Props {
  children: React.ReactNode
}

export function ProvideCreateWorkplaceTargetRevenueSettingDialog({
  children,
}: Props) {
  const value = useProvideCreateWorkplaceTargetRevenueSettingDialog()

  return (
    <context.Provider value={value}>
      {children}
      {value.workplaceId && (
        <CreateWorkplaceTargetRevenueSettingDialog
          open={value.open}
          onClose={value.closeDialog}
          workplaceId={value.workplaceId}
        />
      )}
    </context.Provider>
  )
}

export function useCreateWorkplaceTargetRevenueSettingDialog() {
  return useContext(context)
}

function useProvideCreateWorkplaceTargetRevenueSettingDialog() {
  const [open, setOpen] = useState(false)
  const [workplaceId, setWorkplaceId] = useState<string | null>()

  const showDialog = (targetRevenueSettingWorkplaceId: string) => {
    setWorkplaceId(targetRevenueSettingWorkplaceId)
    setOpen(true)
  }

  const closeDialog = () => setOpen(false)

  return {
    open,
    closeDialog,
    showDialog,
    workplaceId,
  }
}
