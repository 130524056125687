import WorkplaceTargetRevenueSettings from './WorkplaceTargetRevenueSettings'
import { ProvideCreateWorkplaceTargetRevenueSettingDialog } from './_components/CreateWorkplaceTargetRevenueSettingDialog'
import { ProvideEditWorkplaceTargetRevenueSettingDialog } from './_components/EditWorkplaceTargetRevenueSettingDialog'

interface Props {
  workplaceId: string
}

function WorkplaceTargetRevenueSettingsComponent({ workplaceId }: Props) {
  return (
    <ProvideCreateWorkplaceTargetRevenueSettingDialog>
      <ProvideEditWorkplaceTargetRevenueSettingDialog>
        <WorkplaceTargetRevenueSettings workplaceId={workplaceId} />
      </ProvideEditWorkplaceTargetRevenueSettingDialog>
    </ProvideCreateWorkplaceTargetRevenueSettingDialog>
  )
}

export default WorkplaceTargetRevenueSettingsComponent
