import React from 'react'
import Header from './_components/Header'
import styles from './index.module.css'
import useWorkplaceId from '../../_hooks/useWorkplaceId'

const AnnualReportsChart = React.lazy(
  () => import('./_components/AnnualReportsChart')
)

function AnnualReportsPage() {
  const workplaceId = useWorkplaceId()

  if (!workplaceId) return null
  return (
    <div className={styles.root}>
      <Header />
      <AnnualReportsChart />
    </div>
  )
}

export default AnnualReportsPage
