import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useSearchParams } from 'react-router-dom'
import useYear from '../../../../_hooks/useYear'

function YearSelect() {
  const [, setSearchParams] = useSearchParams()
  const year = useYear()
  const years = ['2025', '2024', '2023']

  const onSelect = (event: SelectChangeEvent<string>) => {
    setSearchParams(prev => {
      prev.delete('year')
      prev.append('year', event.target.value)
      return prev
    })
  }

  return (
    <FormControl
      style={{ width: 160, marginRight: 8, flexShrink: 0 }}
      variant="outlined"
    >
      <Select value={year} onChange={onSelect}>
        {years.map(yr => (
          <MenuItem value={yr}>
            <Typography style={{ fontWeight: 'bold', color: 'grey' }}>
              {yr} 年
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default YearSelect
