import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import DownIcon from '@mui/icons-material/ArrowDropDown'
import UpIcon from '@mui/icons-material/ArrowDropUp'
import format from 'date-fns/format'
import React, { useRef, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import parseDate from 'helpers/parseDate'
import DatePicker from '../DatePicker'

// const DatePicker = React.lazy(() => import('../DatePicker'))

interface Props {
  disabled?: Boolean
  minDate?: Date
  maxDate?: Date
  date: Date
  disabledDay?: (date: Date) => boolean
  onChange: (date: Date) => void
}

function DatePickerDropdown({
  disabled,
  minDate,
  maxDate,
  date,
  disabledDay,
  onChange,
}: Props) {
  const anchorEl = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOnChange = (date: Date) => {
    setOpen(false)
    onChange(date)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <div
          ref={anchorEl}
          onClick={() => setOpen(!open)}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 4,
            border: '1px solid #ced4da',
            padding: '10px 12px',
            paddingRight: 4,
            userSelect: 'none',
          }}
        >
          <Typography
            style={{ marginRight: 2, color: disabled ? 'grey' : 'default' }}
          >
            {date ? format(parseDate(date), 'yyyy/MM/dd') : '--'}
          </Typography>
          {open ? (
            <UpIcon style={{ color: 'grey' }} />
          ) : (
            <DownIcon style={{ color: 'grey' }} />
          )}
        </div>
        {!disabled && (
          <Popper
            open={open}
            anchorEl={anchorEl.current}
            style={{ zIndex: 2000 }}
          >
            <Paper>
              <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                date={parseDate(date)}
                disabledDay={disabledDay}
                onChange={handleOnChange}
              />
            </Paper>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DatePickerDropdown
