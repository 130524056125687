import AddIcon from '@mui/icons-material/AddAPhoto'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import addImage from 'api/workplace/dailyReportTemplate/addImage'
import removeImage from 'api/workplace/dailyReportTemplate/removeImage'
import { sessionAtom } from 'atoms/Session'
import useWorkplaceId from 'components/pages/ReportsPage/_hooks/useWorkplaceId'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import getImageBase64 from 'utils/getImageBase64'
import getImageDimensions from 'utils/getImageDimensions'
import { dailyReportTemplateAtom } from '../../../../_atoms/DailyReportTemplate'
import ImageUploadButton from './_components/ImageUploadButton'

function Images() {
  const session = useAtomValue(sessionAtom)
  const workplaceId = useWorkplaceId()
  const [dailyReportTemplate, queryDailyReportTemplate] = useAtom(
    dailyReportTemplateAtom(workplaceId)
  )

  useEffect(() => queryDailyReportTemplate(), [queryDailyReportTemplate])

  const [images, setImages] = useState<any[]>([])

  useEffect(() => {
    if (!dailyReportTemplate?.images) return
    setImages(dailyReportTemplate.images.map(image => image!.file!) || [])
  }, [dailyReportTemplate])

  const onRemoveImage = async (index: number) => {
    await removeImage({ imageId: dailyReportTemplate?.images![index]!.id! })

    setImages([...images.slice(0, index)])
    queryDailyReportTemplate()
  }

  const onSelectImage = (index: number) => async (image: File) => {
    const url = URL.createObjectURL(image)
    const { width, height } = await getImageDimensions(url)
    const b64Image = await getImageBase64(url)

    await addImage({
      b64Image,
      filename: `DailyReportTemplate_#${index + 1}.jpg`,
      employeeId: session.employeeId!,
      workplaceId: workplaceId!,
      index,
      width,
      height,
      aspectRatio: height / width,
    })
    queryDailyReportTemplate()

    setImages([...images, image])
  }

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          margin: '0 16px',
          zIndex: 2,
          padding: 0,
        }}
        item
        xs={12}
        textAlign="center"
      >
        <Typography
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'grey',
          }}
        >
          照片回報 (左側為範例)
        </Typography>
      </Grid>

      {[...Array(5).keys()].map(index => (
        <>
          <Grid item xs={6}>
            <ImageUploadButton
              removable={index === images.length - 1}
              disabled={images.length < index}
              index={index + 1}
              image={images[index]}
              onRemove={() => onRemoveImage(index)}
              onSelect={onSelectImage(index)}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed #ddd',
                height: 100,
                fontSize: 14,
                fontWeight: 'bold',
                color: 'grey',
              }}
            >
              <AddIcon />
            </div>
          </Grid>
        </>
      ))}
    </Grid>
  )
}

export default Images
