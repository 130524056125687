import { BusinessWorkplaceTargetRevenueSetting } from 'gql/types'
import React, { createContext, useContext, useState } from 'react'
import EditWorkplaceTargetRevenueSettingDialog from './EditWorkplaceTargetRevenueSettingDialog'

interface ContextProps {
  open: boolean
  closeDialog: () => void
  showDialog: (
    targetRevenueSetting: BusinessWorkplaceTargetRevenueSetting,
    workplaceId: string
  ) => void
}

const context = createContext<ContextProps | null>(null)

interface Props {
  children: React.ReactNode
}

export function ProvideEditWorkplaceTargetRevenueSettingDialog({
  children,
}: Props) {
  const value = useProvideEditWorkplaceTargetRevenueSettingDialog()

  return (
    <context.Provider value={value}>
      {children}
      {value.targetRevenueSetting && value.workplaceId && (
        <EditWorkplaceTargetRevenueSettingDialog
          open={value.open}
          onClose={value.closeDialog}
          targetRevenueSetting={value.targetRevenueSetting}
          workplaceId={value.workplaceId}
        />
      )}
    </context.Provider>
  )
}

export function useEditWorkplaceTargetRevenueSettingDialog() {
  return useContext(context)
}

function useProvideEditWorkplaceTargetRevenueSettingDialog() {
  const [open, setOpen] = useState(false)
  const [targetRevenueSetting, setTargetRevenueSetting] =
    useState<BusinessWorkplaceTargetRevenueSetting | null>()
  const [workplaceId, setWorkplaceId] = useState<string | null>()

  const showDialog = (
    settingToEdit: BusinessWorkplaceTargetRevenueSetting,
    workplaceIdToEdit: string
  ) => {
    setTargetRevenueSetting(settingToEdit)
    setWorkplaceId(workplaceIdToEdit)
    setOpen(true)
  }

  const closeDialog = () => setOpen(false)

  return {
    open,
    closeDialog,
    showDialog,
    targetRevenueSetting,
    workplaceId,
  }
}
