import { useAtomValue } from 'jotai'
import WorkplaceSelect from '../../../WorkplaceSelect'
import ChartButton from './_components/ChartButton'
import MonthSelect from './_components/MonthSelect'
import Stats from './_components/Stats'
import styles from './index.module.css'
import { businessModeAtom } from 'atoms/Session'

function Header() {
  const businessMode = useAtomValue(businessModeAtom)

  return (
    <div className={styles.root}>
      {businessMode && <WorkplaceSelect />}
      <MonthSelect />
      <Stats />
      <ChartButton />
    </div>
  )
}

export default Header
