import { businessModeAtom } from 'atoms/Session'
import { useAtomValue } from 'jotai'
import BusinessReportsPage from './BusinessReportsPage'
import WorkplaceReportsPage from './WorkplaceReportsPage'

function ReportsPage() {
  const businessMode = useAtomValue(businessModeAtom)

  return businessMode ? <BusinessReportsPage /> : <WorkplaceReportsPage />
}

export default ReportsPage
