import UsersIcon from '@mui/icons-material/Group'
import CurrencyDollarIcon from '@mui/icons-material/LocalAtm'
import OvertimeIcon from '@mui/icons-material/MoreTime'
import Typography from '@mui/material/Typography'
import { BusinessWorkplaceDailyReport } from 'gql/types'
import currencyFormatter from 'helpers/currencyFormatter'
import getRoundedNumber from 'helpers/getRoundedNumber'
import { useState } from 'react'
import DailyReportDialog from './_components/DailyReportDialog'
import Margin from './_components/Margin'
import styles from './index.module.css'
import useDailyReport from './_hooks/useDailyReport'
import { CircularProgress } from '@mui/material'

interface Props {
  dailyReport: BusinessWorkplaceDailyReport
  onClick: () => void
}

function DailyReport({ dailyReport, onClick }: Props) {
  if (!dailyReport.revenue && dailyReport.revenue !== 0) {
    return (
      <div className={styles.empty} onClick={onClick}>
        <Typography color="primary" variant="body2" style={{ marginBottom: 8 }}>
          未回報
        </Typography>
      </div>
    )
  }

  if (dailyReport.isRestDay) {
    return (
      <div className={styles.rest}>
        <Typography variant="body2" style={{ marginBottom: 8, color: 'grey' }}>
          店休
        </Typography>
      </div>
    )
  }

  const overtimeHours = dailyReport.overtimeHours || 0

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.row}>
        <CurrencyDollarIcon className={styles.icon} />
        <Typography className={styles.label}>
          {currencyFormatter.format(dailyReport.revenue || 0)}
        </Typography>
        <div style={{ flex: 1 }} />
        <Margin margin={dailyReport.margin} />
      </div>

      <div className={styles.row}>
        <div className={styles.entity}>
          <UsersIcon className={styles.icon} />
          <Typography className={styles.label}>
            {dailyReport.customers}
          </Typography>
        </div>
        <div style={{ flex: 1 }} />
      </div>

      <div className={styles.row}>
        <div className={styles.entity}>
          <OvertimeIcon
            className={styles.icon}
            style={{ color: overtimeHours > 0 ? 'darkorange' : 'grey' }}
          />
          <Typography
            className={styles.label}
            style={{ color: overtimeHours > 0 ? 'darkorange' : 'grey' }}
          >
            {getRoundedNumber(overtimeHours)}
          </Typography>
        </div>
      </div>
    </div>
  )
}

interface ComponentProps {
  date: Date
}

const DailyReportComponent = ({ date }: ComponentProps) => {
  const dailyReport = useDailyReport(date)
  const [showDailyReportDialog, setShowDailyReportDialog] = useState(false)
  const handleShowDailyReportDialog = () => setShowDailyReportDialog(true)
  const handleCloseDailyReportDialog = () => setShowDailyReportDialog(false)

  if (!dailyReport) {
    return (
      <div
        className={styles.wrapper}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={20} />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <DailyReport
        dailyReport={dailyReport}
        onClick={handleShowDailyReportDialog}
      />

      {showDailyReportDialog && (
        <DailyReportDialog
          dailyReport={dailyReport}
          onClose={handleCloseDailyReportDialog}
        />
      )}
    </div>
  )
}

export default DailyReportComponent
