import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { businessModeAtom } from 'atoms/Session'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DailyReportsPage from './_components/DailyReportsPage'
import MonthlyReportsPage from './_components/MonthlyReportsPage'
import AnnualReportsPage from './_components/AnnualReportsPage'
import DailyReportTemplate from './_components/DailyReportTemplate'
import WorkplaceTargetRevenueSettings from '../TargetRevenueSettingsPage/_components/WorkplaceTargetRevenueSettings'
import queryString from 'query-string'
import useWorkplaceId from './_hooks/useWorkplaceId'
import { authAtom } from 'atoms/Auth'

function WorkplaceReportsPage() {
  const auth = useAtomValue(authAtom)
  const workplaceId = useWorkplaceId()
  const navigate = useNavigate()
  const location = useLocation()
  const { tab } = queryString.parse(location.search)
  const businessMode = useAtomValue(businessModeAtom)

  const [activeIndex, setActiveIndex] = useState(
    tab ? parseInt((tab || '0') as string) : 0
  )

  useEffect(
    () => setActiveIndex(tab ? parseInt((tab || '0') as string) : 0),
    [tab]
  )

  const handleActiveIndexChanged = (index: number) => () => {
    setActiveIndex(index)
    navigate(`/reports?tab=${index}`)
  }

  if (!auth || !workplaceId) return null

  return (
    <div
      style={{
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {!false && (
        <List style={{ flexShrink: 0, width: 150 }}>
          <ListItemButton
            selected={activeIndex === 0}
            onClick={handleActiveIndexChanged(0)}
          >
            <ListItemText primary="每日報表" />
          </ListItemButton>
          {businessMode && (
            <ListItemButton
              selected={activeIndex === 1}
              onClick={handleActiveIndexChanged(1)}
            >
              <ListItemText primary="每月報表" />
            </ListItemButton>
          )}
          <ListItemButton
            selected={activeIndex === 2}
            onClick={handleActiveIndexChanged(2)}
          >
            <ListItemText primary="年度報表" />
          </ListItemButton>

          <Divider />

          {auth.hasDailyReportTemplatePrivilege && (
            <ListItemButton
              selected={activeIndex === 3}
              onClick={handleActiveIndexChanged(3)}
            >
              <ListItemText primary="報表設定" />
            </ListItemButton>
          )}
          {!businessMode && auth.hasTargetRevenuePrivilege && (
            <ListItemButton
              selected={activeIndex === 4}
              onClick={handleActiveIndexChanged(4)}
            >
              <ListItemText primary="目標營業額設定" />
            </ListItemButton>
          )}
        </List>
      )}
      {activeIndex === 0 && <DailyReportsPage />}
      {activeIndex === 1 && <MonthlyReportsPage />}
      {activeIndex === 2 && <AnnualReportsPage />}
      {activeIndex === 3 && <DailyReportTemplate />}
      {activeIndex === 4 && (
        <div
          style={{
            padding: 64,
            width: '100%',
            position: 'relative',
          }}
        >
          <WorkplaceTargetRevenueSettings workplaceId={workplaceId} />
        </div>
      )}
    </div>
  )
}

export default WorkplaceReportsPage
