import useWorkplaceId from 'components/pages/ReportsPage/_hooks/useWorkplaceId'
import format from 'date-fns/format'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { workplaceDailyReportsAtom } from '../_atoms/WorkplaceDailyReports'
import useDateRange from './useDateRange'

function useDailyReports() {
  const workplaceId = useWorkplaceId()
  const { startDate, endDate } = useDateRange()
  const sDate = format(startDate, 'yyyy-MM-dd')
  const eDate = format(endDate, 'yyyy-MM-dd')
  const [dailyReports, queryDailyReports] = useAtom(
    workplaceDailyReportsAtom(`${workplaceId}:${sDate}:${eDate}`)
  )

  useEffect(() => queryDailyReports(), [queryDailyReports])

  return dailyReports
}

export default useDailyReports
