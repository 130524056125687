import { workplaceDailyReportsAtom } from 'components/pages/ReportsPage/_components/DailyReportsPage/_atoms/WorkplaceDailyReports'
import useWorkplaceId from 'components/pages/ReportsPage/_hooks/useWorkplaceId'
import format from 'date-fns/format'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

function useDailyReport(date: Date) {
  const dateString = format(date, 'yyyy-MM-dd')
  const workplaceId = useWorkplaceId()
  const [dailyReports, queryDailyReports] = useAtom(
    workplaceDailyReportsAtom(`${workplaceId}:${dateString}:${dateString}`)
  )

  useEffect(() => queryDailyReports(), [queryDailyReports])

  return dailyReports ? dailyReports[0] : null
}

export default useDailyReport
