import { businessModeAtom, sessionAtom } from 'atoms/Session'
import { workplacesAtom } from 'components/pages/ReportsPage/_atoms/Workplaces'
import { useAtom, useAtomValue } from 'jotai'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useWorkplaceId() {
  const location = useLocation()
  const [workplaces, queryWorkplaces] = useAtom(workplacesAtom)
  const businessMode = useAtomValue(businessModeAtom)
  const session = useAtomValue(sessionAtom)

  useEffect(() => queryWorkplaces(), [queryWorkplaces])

  if (!businessMode) return session.workplaceId
  if (!workplaces) return undefined

  const activeWorkplaces = workplaces.filter(({ active }) => active)

  const { workplaceId } = queryString.parse(location.search)
  const workplace = workplaceId
    ? workplaces?.find(({ id }) => id === workplaceId)
    : activeWorkplaces[0]
  return workplace?.id
}

export default useWorkplaceId
