import * as Sentry from '@sentry/react'
import { authAtom } from 'atoms/Auth'
import { businessModeAtom, sessionAtom } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import { workplacesAtom } from 'atoms/Session/Business/Workplaces'
import AdminOnlyPage from 'components/pages/AdminOnlyPage'
import ImpersonatorPrompt from 'components/prompts/ImpersonatorPrompt'
import NewAppVersionPrompt from 'components/prompts/NewAppVersionPrompt'
import RenewalPrompt from 'components/prompts/RenewalPrompt'
import { getEnv } from 'env'
import useIsSessionExpired from 'hooks/Auth/useIsSessionExpired'
import { useAtomValue, useSetAtom } from 'jotai'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSocket } from 'websocket/provider'

function AuthOnly({ children }: { children: JSX.Element }) {
  const sessionExpired = useIsSessionExpired()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const socket = useSocket()
  const auth = useAtomValue(authAtom)
  const session = useAtomValue(sessionAtom)
  const business = useAtomValue(businessAtom)
  const businessMode = useAtomValue(businessModeAtom)
  const workplaces = useAtomValue(workplacesAtom)
  const queryBusiness = useSetAtom(businessAtom)
  const queryWorkplaces = useSetAtom(workplacesAtom)

  useEffect(() => queryBusiness(), [queryBusiness])
  useEffect(() => queryWorkplaces(), [queryWorkplaces])

  useEffect(() => {
    if (sessionExpired) {
      window.localStorage.removeItem('session')
      window.location.href = getEnv().websiteUrl
      navigate('/login', { replace: true })
    }
  }, [navigate, session, sessionExpired])

  useEffect(() => {
    if (socket?.unAuthenticated) {
      Sentry.setContext('session', { session })
      Sentry.captureMessage('AuthOnly socket unAuthenticated')
      window.localStorage.removeItem('session')
      navigate('/login', { replace: true })
    }
  }, [navigate, session, socket?.unAuthenticated])

  useEffect(() => {
    if (
      business &&
      workplaces &&
      auth &&
      ((!auth.hasAttendancePrivilege && pathname.startsWith('/attendance')) ||
        (!auth.hasPayrollPrivilege && pathname.startsWith('/payroll')) ||
        (!auth.hasSchedulingPrivilege && pathname.startsWith('/schedule')) ||
        (!auth.hasSchedulingPrivilege && pathname.startsWith('/positions')) ||
        (!auth.hasSchedulingPrivilege &&
          pathname.startsWith('/masterSchedule')) ||
        (!auth.hasStaffManagementPrivilege &&
          pathname.startsWith('/employees')) ||
        (!auth.hasTimeClockPrivilege && pathname.startsWith('/timeClock')) ||
        (!auth.hasFullPrivilege && pathname.startsWith('/settings')) ||
        (businessMode &&
          (pathname === '/attendance' ||
            pathname === '/payroll' ||
            pathname === '/schedule' ||
            pathname === '/positions' ||
            pathname === '/masterSchedule')))
    ) {
      navigate('/', { replace: true })
    }
  }, [pathname, navigate, business, auth, workplaces, businessMode])

  useEffect(() => {
    if (session.businessId) {
      Cookies.set('businessId', session.businessId, {
        domain: `.${window.location.hostname}`,
        expires: 365,
      })
    } else {
      Cookies.remove('businessId')
    }
    if (session.workplaceId) {
      Cookies.set('workplaceId', session.workplaceId, {
        domain: `.${window.location.hostname}`,
        expires: 365,
      })
    } else {
      Cookies.remove('workplaceId')
    }
  }, [business, session.businessId, session.workplaceId])

  // if (!auth?.isOwner && session.isIncumbent !== null && !session.isIncumbent)
  //   return <AdminOnlyPage />

  if (!auth) return null

  if (auth.noPrivilege) {
    return <AdminOnlyPage />
  }

  return (
    // <NotSupportedTimezonePrompt>
    <ImpersonatorPrompt>
      <RenewalPrompt>
        <NewAppVersionPrompt>{children}</NewAppVersionPrompt>
      </RenewalPrompt>
    </ImpersonatorPrompt>
    // </NotSupportedTimezonePrompt>
  )
}

export default AuthOnly
