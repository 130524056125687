import { useAtom } from 'jotai'
import { dailyReportTemplateAtom } from '../_atoms/DailyReportTemplate'
import { useEffect } from 'react'
import useWorkplaceId from 'components/pages/ReportsPage/_hooks/useWorkplaceId'

function useDailyReportTemplate() {
  const workplaceId = useWorkplaceId()
  const [dailyReportTemplate, query] = useAtom(
    dailyReportTemplateAtom(workplaceId)
  )

  useEffect(() => query(), [query])

  return dailyReportTemplate
}

export default useDailyReportTemplate
