import axios from 'axios'
import { getEnv } from 'env'
import Cookies from 'js-cookie'

function deleteCookie(name: string) {
  document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/`

  // Remove it from local storage too
  window.localStorage.removeItem(name)
}

function deleteAllCookies() {
  const cookies = document.cookie.split('; ')

  cookies.forEach(cookie => {
    const name = cookie.split('=').shift()
    if (name && !name.startsWith('CF_')) {
      deleteCookie(name)
    }
  })

  // Some sites backup cookies in `localStorage`
  window.localStorage.clear()
}

const logout = async () => {
  Cookies.remove('uid')
  await axios.post(
    `${getEnv().authApiUrl}/logout`,
    {},
    {
      withCredentials: true,
    }
  )
  window.localStorage.removeItem('session')
  window.location.href = getEnv().websiteUrl
  deleteAllCookies()
}

export default logout
