import useYear from 'components/pages/ReportsPage/_components/AnnualReportsPage/_hooks/useYear'
import useWorkplaceId from 'components/pages/ReportsPage/_hooks/useWorkplaceId'
import addMonths from 'date-fns/addMonths'
import differenceInMonths from 'date-fns/differenceInMonths'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import { gqlClientAtom } from 'gql'
import getToday from 'helpers/getToday'
import parseDate from 'helpers/parseDate'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { DailyReportsPageHeader_LaborCost } from '../_atoms/LaborCost/_gql'

interface LaborCost {
  key: string
  laborCost: number
}

function useLaborCosts() {
  const client = useAtomValue(gqlClientAtom)
  const workplaceId = useWorkplaceId()
  const today = getToday()
  const year = useYear()
  const [laborCosts, setLaborCosts] = useState<LaborCost[]>([])
  const [progress, setProgress] = useState(0)
  const refDate = parseDate(`${year}-01-01`)
  const months = Math.min(12, differenceInMonths(today, refDate) + 1)

  useEffect(() => {
    const queryData = async () => {
      if (!client) return
      await Promise.all(
        [...Array(months).keys()].map(index => {
          const startDate = format(addMonths(refDate, index), 'yyyy-MM-dd')
          const endDate = format(
            endOfMonth(addMonths(refDate, index)),
            'yyyy-MM-dd'
          )
          const key = format(parseDate(startDate), 'yyyy / M')

          return client
            .query(DailyReportsPageHeader_LaborCost, {
              workplaceId,
              startDate,
              endDate,
            })
            .toPromise()
            .then(result =>
              setLaborCosts(
                prev =>
                  [
                    ...prev.filter(laborCost => laborCost.key !== key),
                    {
                      key,
                      laborCost: result?.data?.businessWorkplaceLaborCost,
                    },
                  ] as LaborCost[]
              )
            )
        })
      )
    }
    queryData()
  }, [client, months, refDate, workplaceId, year])

  useEffect(
    () => setProgress((laborCosts.length / months) * 100),
    [laborCosts.length, months]
  )

  const yearLaborCosts = useMemo(
    () => laborCosts.filter(({ key }) => key.startsWith(year)),
    [year, laborCosts]
  )

  return {
    laborCosts: yearLaborCosts,
    progress,
  }
}

export default useLaborCosts
