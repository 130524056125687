import { sessionAtom } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import { atom } from 'jotai'

const hasPrivilege = (privilege: string, privileges: string[]) =>
  privileges.includes(privilege) || false

export const authAtom = atom(get => {
  const business = get(businessAtom)
  const session = get(sessionAtom)

  if (!business) return null

  const isOwner = business.isOwner || false
  const isAdmin = business.isAdmin || false

  const workplace = [
    business.myWorkplace,
    ...business.myPrivilegedWorkplaces,
  ].find(workplace => workplace.id === session.workplaceId)

  const privileges = workplace?.privileges || []

  const hasFullPrivilege = isOwner || isAdmin || hasPrivilege('all', privileges)

  const hasAttendancePrivilege =
    hasFullPrivilege || hasPrivilege('attendance', privileges)

  const hasSchedulingPrivilege =
    hasFullPrivilege || hasPrivilege('scheduling', privileges)

  const hasStaffManagementPrivilege =
    hasFullPrivilege || hasPrivilege('staff_management', privileges)

  const hasPayrollPrivilege =
    hasFullPrivilege || hasPrivilege('payroll', privileges)

  const hasTimeClockPrivilege =
    hasFullPrivilege ||
    hasAttendancePrivilege ||
    hasPrivilege('time_clock', privileges)

  const hasDailyReportPrivilege =
    hasFullPrivilege || hasPrivilege('daily_report', privileges)

  const hasDailyReportTemplatePrivilege =
    hasFullPrivilege || hasPrivilege('daily_report_template', privileges)

  const hasTargetRevenuePrivilege =
    hasFullPrivilege || hasPrivilege('target_revenue', privileges)

  const noPrivilege =
    !hasFullPrivilege &&
    !hasAttendancePrivilege &&
    !hasSchedulingPrivilege &&
    !hasStaffManagementPrivilege &&
    !hasPayrollPrivilege &&
    !hasTimeClockPrivilege &&
    !hasDailyReportPrivilege &&
    !hasDailyReportTemplatePrivilege &&
    !hasTargetRevenuePrivilege

  const hasPrivilegedWorkplaces = business.myPrivilegedWorkplaces.length > 0

  return {
    isOwner,
    isAdmin,
    hasFullPrivilege,
    hasAttendancePrivilege,
    hasSchedulingPrivilege,
    hasStaffManagementPrivilege,
    hasPayrollPrivilege,
    hasTimeClockPrivilege,
    hasDailyReportPrivilege,
    hasDailyReportTemplatePrivilege,
    hasTargetRevenuePrivilege,
    noPrivilege,
    hasPrivilegedWorkplaces,
    workplace,
  }
})
