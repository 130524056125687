import { Divider, ListItemButton } from '@mui/material'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AnnualReportsPage from './_components/AnnualReportsPage'
import DailyReportsPage from './_components/DailyReportsPage'
import DailyReportTemplate from './_components/DailyReportTemplate'
import MonthlyReportsPage from './_components/MonthlyReportsPage'

function BusinessReportsPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const { tab } = queryString.parse(location.search)

  const [activeIndex, setActiveIndex] = useState(
    tab ? parseInt((tab || '0') as string) : 0
  )

  useEffect(
    () => setActiveIndex(tab ? parseInt((tab || '0') as string) : 0),
    [tab]
  )

  const handleActiveIndexChanged = (index: number) => () => {
    setActiveIndex(index)
    navigate(`/reports?tab=${index}`)
  }

  return (
    <div
      style={{
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {!false && (
        <List style={{ flexShrink: 0, width: 150 }}>
          <ListItemButton
            selected={activeIndex === 0}
            onClick={handleActiveIndexChanged(0)}
          >
            <ListItemText primary="每日報表" />
          </ListItemButton>
          <ListItemButton
            selected={activeIndex === 1}
            onClick={handleActiveIndexChanged(1)}
          >
            <ListItemText primary="每月報表" />
          </ListItemButton>
          <ListItemButton
            selected={activeIndex === 2}
            onClick={handleActiveIndexChanged(2)}
          >
            <ListItemText primary="年度報表" />
          </ListItemButton>

          <Divider />

          <ListItemButton
            selected={activeIndex === 3}
            onClick={handleActiveIndexChanged(3)}
          >
            <ListItemText primary="報表設定" />
          </ListItemButton>
        </List>
      )}
      {activeIndex === 0 && <DailyReportsPage />}
      {activeIndex === 1 && <MonthlyReportsPage />}
      {activeIndex === 2 && <AnnualReportsPage />}
      {activeIndex === 3 && <DailyReportTemplate />}
      {/* 
      {activeIndex === 0 && <Settlement />}
      {activeIndex === 1 && <PaidPayrolls />}
      {activeIndex === 2 && <Allowances />} */}
    </div>
  )
}

export default BusinessReportsPage
