import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import {
  createTargetRevenueSettingAtom,
  targetRevenueSettingsAtom,
} from 'atoms/Workplace/TargetRevenueSettings'
import DatePickerDropdown from 'components/atoms/DatePickerDropdown'
import TextField from 'components/atoms/TextField'
import format from 'date-fns/format'
import { useAtomValue, useSetAtom } from 'jotai'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  onClose: () => void
  open: boolean
  workplaceId: string
}

function CreateWorkplaceTargetRevenueSettingDialog({
  onClose,
  open,
  workplaceId,
}: Props) {
  const targetRevenueSettings = useAtomValue(
    targetRevenueSettingsAtom(workplaceId)
  )
  const createTargetRevenueSetting = useSetAtom(createTargetRevenueSettingAtom)

  const [startDate, setStartDate] = useState(new Date())
  const [targetRevenueSetting, setTargetRevenueSetting] = useState<any>({})
  const [restDays, setRestDays] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleDatePicked = (date: Date) => setStartDate(date)

  const handleSetRestDay = (key: string) => (event: any, checked: boolean) => {
    if (checked && !restDays.includes(key)) {
      setRestDays(prev => [...prev, key])
    } else {
      setRestDays(prev => prev.filter(day => day !== key))
    }
  }

  const handleTargetRevenueSettingChanged =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) =>
      setTargetRevenueSetting((prev: any) => ({
        ...prev,
        [key]: e.target.value,
      }))

  const handleClose = () => {
    setIsSubmitting(false)
    setStartDate(new Date())
    setTargetRevenueSetting({})
    onClose()
  }

  const handleSave = async () => {
    setIsSubmitting(true)
    await createTargetRevenueSetting({
      targetRevenueSetting: {
        startDate: format(startDate, 'yyyy-MM-dd'),
        workplaceId,
        targetRevenueSettingJson: JSON.stringify({
          mon: !restDays.includes('mon')
            ? targetRevenueSetting.mon * 10000
            : null,
          tue: !restDays.includes('tue')
            ? targetRevenueSetting.tue * 10000
            : null,
          wed: !restDays.includes('wed')
            ? targetRevenueSetting.wed * 10000
            : null,
          thu: !restDays.includes('thu')
            ? targetRevenueSetting.thu * 10000
            : null,
          fri: !restDays.includes('fri')
            ? targetRevenueSetting.fri * 10000
            : null,
          sat: !restDays.includes('sat')
            ? targetRevenueSetting.sat * 10000
            : null,
          sun: !restDays.includes('sun')
            ? targetRevenueSetting.sun * 10000
            : null,
          holiday: !restDays.includes('holiday')
            ? targetRevenueSetting.holiday * 10000
            : null,
        }),
      },
    })
    handleClose()
  }

  const isDateValid = !targetRevenueSettings?.find(
    setting => setting.startDate === format(startDate, 'yyyy-MM-dd')
  )

  if (!open) return null

  return (
    <Dialog onClose={handleClose} open>
      <DialogTitle>新增目標營業額設定</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            <Typography>設定生效日</Typography>
            <DatePickerDropdown date={startDate} onChange={handleDatePicked} />
          </div>
          {[
            ['週一', 'mon'],
            ['週二', 'tue'],
            ['週三', 'wed'],
            ['週四', 'thu'],
            ['週五', 'fri'],
            ['週六', 'sat'],
            ['週日', 'sun'],
            ['國定假日', 'holiday'],
          ].map(([label, key]) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 8,
                height: 70,
              }}
            >
              <Typography>{label}</Typography>
              <div style={{ flex: 1 }} />
              {!restDays.includes(key) && (
                <TextField
                  type="number"
                  endAdornment={
                    <InputAdornment style={{ marginRight: 8 }} position="end">
                      萬
                    </InputAdornment>
                  }
                  value={targetRevenueSetting[key]}
                  onChange={handleTargetRevenueSettingChanged(key)}
                />
              )}

              <div style={{ width: 16 }} />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={restDays.includes(key)}
                    onChange={handleSetRestDay(key)}
                  />
                }
                label="店休"
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={
            isSubmitting || !startDate || !targetRevenueSetting || !isDateValid
          }
          startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          onClick={handleSave}
        >
          <FormattedMessage
            defaultMessage="Create"
            id="H5NKfr"
            description="button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateWorkplaceTargetRevenueSettingDialog
