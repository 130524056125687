import { sessionAtom } from 'atoms/Session'
import { gqlClientAtom } from 'gql'
import { BusinessWorkplace } from 'gql/types'
import { atom } from 'jotai'
import { SetDefaultDomainDialog_MyWorkplaces } from './_gql'

const queryResultAtom = atom<BusinessWorkplace[] | null>(null)

export const workplacesAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      const businessId = get(sessionAtom)?.businessId
      if (!client || !businessId) return
      const result = await client
        .query(SetDefaultDomainDialog_MyWorkplaces, {
          businessId,
        })
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

const parse = (result: any): BusinessWorkplace[] | null => [
  result?.data?.node.myWorkplace,
  ...result?.data?.node.myPrivilegedWorkplaces.filter(
    ({ id }: any) => id !== result?.data?.node.myWorkplace.id
  ),
]
