import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import {
  deleteTargetRevenueSettingAtom,
  targetRevenueSettingsAtom,
  updateTargetRevenueSettingAtom,
} from 'atoms/Workplace/TargetRevenueSettings'
import DatePickerDropdown from 'components/atoms/DatePickerDropdown'
import TextField from 'components/atoms/TextField'
import format from 'date-fns/format'
import { BusinessWorkplaceTargetRevenueSetting } from 'gql/types'
import parseDate from 'helpers/parseDate'
import { useAtom, useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  onClose: () => void
  open: boolean
  targetRevenueSetting: BusinessWorkplaceTargetRevenueSetting
  workplaceId: string
}

function EditWorkplaceTargetRevenueSettingDialog({
  onClose,
  open,
  targetRevenueSetting: setting,
  workplaceId,
}: Props) {
  const [targetRevenueSettings, queryTargetRevenueSettings] = useAtom(
    targetRevenueSettingsAtom(workplaceId)
  )
  const updateTargetRevenueSetting = useSetAtom(updateTargetRevenueSettingAtom)
  const deleteTargetRevenueSetting = useSetAtom(deleteTargetRevenueSettingAtom)

  const [startDate, setStartDate] = useState(parseDate(setting.startDate))
  const [targetRevenueSetting, setTargetRevenueSetting] = useState<any>(setting)
  const [restDays, setRestDays] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => queryTargetRevenueSettings(), [queryTargetRevenueSettings])
  useEffect(() => {
    if (setting) {
      setStartDate(parseDate(setting.startDate))
      setTargetRevenueSetting(setting)
      setRestDays(
        Object.entries(setting)
          .filter(([, value]) => !value)
          .map(([key]) => key)
      )
    }
  }, [setting])

  const handleDatePicked = (date: Date) => setStartDate(date)

  const handleSetRestDay = (key: string) => (event: any, checked: boolean) => {
    if (checked && !restDays.includes(key)) {
      setRestDays(prev => [...prev, key])
    } else {
      setRestDays(prev => prev.filter(day => day !== key))
    }
  }

  const handleTargetRevenueSettingChanged =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) =>
      setTargetRevenueSetting((prev: any) => ({
        ...prev,
        [key]: parseFloat(e.target.value) * 10000,
      }))

  const handleClose = () => {
    setIsSubmitting(false)
    setStartDate(new Date())
    setTargetRevenueSetting({})
    onClose()
  }
  const handleSave = async () => {
    setIsSubmitting(true)
    await updateTargetRevenueSetting({
      targetRevenueSetting: {
        id: setting.id,
        workplaceId,
        startDate: format(startDate, 'yyyy-MM-dd'),
        targetRevenueSettingJson: JSON.stringify({
          mon: !restDays.includes('mon') ? targetRevenueSetting.mon : null,
          tue: !restDays.includes('tue') ? targetRevenueSetting.tue : null,
          wed: !restDays.includes('wed') ? targetRevenueSetting.wed : null,
          thu: !restDays.includes('thu') ? targetRevenueSetting.thu : null,
          fri: !restDays.includes('fri') ? targetRevenueSetting.fri : null,
          sat: !restDays.includes('sat') ? targetRevenueSetting.sat : null,
          sun: !restDays.includes('sun') ? targetRevenueSetting.sun : null,
          holiday: !restDays.includes('holiday')
            ? targetRevenueSetting.holiday
            : null,
        }),
      },
    })
    handleClose()
  }

  const handleDelete = async () => {
    await deleteTargetRevenueSetting({
      workplaceId,
      targetRevenueSettingId: setting.id,
    })
    handleClose()
  }

  if (!targetRevenueSetting || !open) return null

  const isDateValid = !targetRevenueSettings?.find(
    ({ id, startDate: sDate }) =>
      id !== setting.id && sDate === format(startDate, 'yyyy-MM-dd')
  )

  return (
    <Dialog onClose={handleClose} open>
      <DialogTitle>編輯目標營業額設定</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            <Typography>設定生效日</Typography>
            <DatePickerDropdown date={startDate} onChange={handleDatePicked} />
          </div>
          {[
            ['週一', 'mon'],
            ['週二', 'tue'],
            ['週三', 'wed'],
            ['週四', 'thu'],
            ['週五', 'fri'],
            ['週六', 'sat'],
            ['週日', 'sun'],
            ['國定假日', 'holiday'],
          ].map(([label, key]) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 8,
                height: 70,
              }}
            >
              <Typography>{label}</Typography>
              <div style={{ flex: 1 }} />
              {!restDays.includes(key) && (
                <TextField
                  type="number"
                  endAdornment={
                    <InputAdornment style={{ marginRight: 8 }} position="end">
                      萬
                    </InputAdornment>
                  }
                  value={targetRevenueSetting[key] / 10000}
                  onChange={handleTargetRevenueSettingChanged(key)}
                />
              )}

              <div style={{ width: 16 }} />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={restDays.includes(key)}
                    onChange={handleSetRestDay(key)}
                  />
                }
                label="店休"
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{ color: 'red' }}
          onClick={handleDelete}
        >
          刪除設定
        </Button>
        <div style={{ flex: 1 }} />
        <Button
          color="primary"
          variant="contained"
          disabled={
            isSubmitting || !startDate || !targetRevenueSetting || !isDateValid
          }
          startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          onClick={handleSave}
        >
          <FormattedMessage
            defaultMessage="Save"
            id="RaycYK"
            description="button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditWorkplaceTargetRevenueSettingDialog
