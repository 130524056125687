import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import { authAtom } from 'atoms/Auth'
import { businessModeAtom } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import isLowRes from 'helpers/isLowRes'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import SetDefaultDomainDialog from './_components/SetDefaultDomainDialog'

function DomainButton() {
  const auth = useAtomValue(authAtom)
  const businessMode = useAtomValue(businessModeAtom)
  const business = useAtomValue(businessAtom)
  const [showDialog, setShowDialog] = useState(false)

  if (!auth || !business) return null

  const isPrivileged =
    auth.isOwner || auth.isAdmin || auth.hasPrivilegedWorkplaces

  return (
    <>
      <Button
        style={{
          textTransform: 'none',
          color: 'white',
          fontSize: isLowRes ? 14 : 18,
        }}
        disabled={!isPrivileged}
        size="medium"
        onClick={() => setShowDialog(true)}
        endIcon={isPrivileged ? <ArrowDropDownIcon /> : null}
      >
        <span>{businessMode ? '請選擇檢視門店' : auth.workplace!.name}</span>
      </Button>
      <SetDefaultDomainDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </>
  )
}

export default DomainButton
