import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useSearchParams } from 'react-router-dom'
import useWorkplaces from '../../_hooks/useWorkplaces'
import useWorkplaceId from '../../_hooks/useWorkplaceId'

function WorkplaceSelect() {
  const [, setSearchParams] = useSearchParams()
  const workplaces = useWorkplaces()
  const workplaceId = useWorkplaceId()

  const onSelect = (event: SelectChangeEvent<string>) => {
    setSearchParams(prev => {
      prev.delete('workplaceId')
      prev.append('workplaceId', event.target.value)
      return prev
    })
  }

  if (!workplaceId) return null

  return (
    <FormControl
      style={{ margin: '0 20px', width: 240, flexShrink: 0 }}
      variant="outlined"
    >
      <Select value={workplaceId} onChange={onSelect}>
        {workplaces
          .filter(({ active }) => active)
          .map(workplace => (
            <MenuItem value={workplace.id}>{workplace.name}</MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default WorkplaceSelect
